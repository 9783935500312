/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import {getScreenHeight, isMobile} from './helper.js';
import $ from 'jquery';

const toggleMenu = () => {
	let button = document.getElementById('toggle'),
		container = document.getElementById('site-navigation'),
		html = document.querySelector('html');

		if (button && container) {
			button.addEventListener('click', () => {

				if(button.classList.contains('open')) {
					container.classList.remove('open');
					button.classList.remove('open');
					html.style.overflowY = 'auto';		
					return;
				}

				container.classList.add('open');
				button.classList.add('open');

				if (getScreenHeight() <= 1260) {
					html.style.overflowY = 'hidden';
				} else {
					html.style.overflowY = 'auto';					
				}
	
			}) 
		}
} 
toggleMenu();

const addToggleMenu = () => {
	let toggle = document.getElementById('toggle'),
		header = document.getElementById('masthead');

	toggle.addEventListener('click', () => {
		if(header.classList.contains('toggled-menu')) {
			header.classList.remove('toggled-menu')
			return
		}
		header.classList.add('toggled-menu');
	})
	
}

if (isMobile()) {
	addToggleMenu();
}

(function($){
	$('#site-navigation .menu-item-has-children > a').on('click',function(e){
		var device_width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		if (device_width < 1260) {	
			e.preventDefault();

			var li = $(this).closest('li');
			var ul = li.children('ul');
			li.toggleClass('toggled')
			if (ul.attr('aria-expanded') == 'true' ) {
				ul.attr('aria-expanded','false');
			} else {
				ul.attr('aria-expanded', 'true');
			}
		};
	});
})(jQuery);

window.onresize = () => {
	if (isMobile()) {
		addToggleMenu();
	}
};