import $ from 'jquery';

let doingAjax = false,
	nextPage = 2,
	postsContainer = $('.posts-list.will-load-posts');

if (postsContainer.length) {

	window.addEventListener('scroll', function(e) {

		let trigger = $(window).scrollTop() + $(window).innerHeight();
		let shouldLoadPosts = trigger > postsContainer.innerHeight() + postsContainer.offset().top;

		e.preventDefault();

		if (!doingAjax && shouldLoadPosts) {

			doingAjax = true;
			postsContainer.addClass('loading');

			$.ajax({
				type: 'POST',
				url: jsData.ajaxUrl,
				data: {
					'action': 'get_posts_news',
					'next_page': nextPage
				},
				success: function(response){

					response = JSON.parse(response);
					postsContainer.append(response.content);
					postsContainer.removeClass('loading');
					nextPage++;

					doingAjax = response.pages_left > 0 ? false : true;
				}
			});
		}
	});
}