const getScreenWidth = () => {
	let w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		width = w.innerWidth || e.clientWidth || g.clientWidth;

	return width;
}

const getScreenHeight = () => {
	let w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		height = w.innerHeight || e.clientHeight || g.clientHeight;

	return height;
}

function isMobile() {
	if (getScreenWidth() <= 768)
		return true;
}

const isPhone = () => {
	if (getScreenWidth() <= 440)
		return true;
}

export {getScreenWidth, getScreenHeight, isMobile, isPhone}